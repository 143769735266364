import React, { useState, useEffect, useContext } from 'react';

const WindowWidthContext = React.createContext({});

/**
 * @example To consume and subscribe to this context wrap it in
 * <WindowWidthContext.Consumer>
 *  {({ width }) => (
 *    <Component />
 *  )}
 * </WindowWidthContext.Consumer>
 */
// class WindowWidthProvider extends React.Component {
//   state = {
//     height: 0,
//     width: 0,
//   };

//   setWindowSize = () => {
//     this.setState({ width: window.innerWidth, height: window.innerHeight });
//   };

//   componentDidMount() {
//     this.setWindowSize();
//     window.addEventListener('resize', this.setWindowSize);
//   }

//   componentWillUnmount() {
//     window.removeEventListener('resize', this.setWindowSize);
//   }

//   render() {
//     const { children } = this.props;
//     const { width, height } = this.state;

//     return (
//       <WindowWidthContext.Provider value={{ width, height }}>
//         {children}
//       </WindowWidthContext.Provider>
//     );
//   }
// }

const WindowWidthProvider = ({ children }) => {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    if (typeof window === 'undefined') return;

    const setWindowSize = () => {
      const dimensions = {
        width: window.innerWidth,
        height: window.innerHeight,
      };
      setDimensions({ ...dimensions });
    };

    setWindowSize();
    window.addEventListener('resize', setWindowSize);

    return () => {
      window.removeEventListener('resize', setWindowSize);
    };
  }, []);

  const { width, height } = dimensions;

  return (
    <WindowWidthContext.Provider value={{ width, height }}>
      {children}
    </WindowWidthContext.Provider>
  );
};

export default WindowWidthContext;

export { WindowWidthProvider };

export const useWindowWidth = () => {
  const { width, height } = useContext(WindowWidthContext);

  return { width, height };
};
